.klbf-modal-container{
    display: flex;
    min-height: inherit;
    padding-left: 14px;
}
.klbf-modal-container .image-container{
    width: 48%;
    background-image: url(../../../public/assets/images/landing-page/book-fair/klbf/klbf-modal.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: inherit;
}
.klbf-modal-container .content-container{
    width: 60%;
    padding: 24px;
    padding-bottom: 34px;
}
.klbf-modal-container .content-container .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.klbf-modal-container .content-container .logo-wrapper img:first-child{
    width: 75px !important;
    height: 75px !important;
}
.klbf-modal-container .content-container .logo-wrapper img:nth-child(2){
    height: 82px !important;
    width: fit-content;
}
.klbf-modal-container .content-container .title {
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
}
.klbf-modal-container .content-container .title .highlight-title{
    color: #E2001A;
}
.klbf-modal-container .content-container .desc {
    color: #202020;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 26.4px */
    margin: 35px 0 25px 0;
}
.klbf-modal-container .content-container .desc .blue-highlight {
    color: #0078D4;
    font-size: 36.317px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
}
.klbf-modal-container .content-container .desc .bold-text {
    color: #3C4043;
    font-size: 36.317px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
}

.klbf-modal-container .content-container .cta-wrapper{
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.klbf-modal-container .content-container .cta-wrapper img{
    width: 230px;
}
.klbf-modal-container .content-container .cta-wrapper .claim-btn {
    border: none;
    border-radius: 2.8px;
    background-color: #0073DF;
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 13px 45px;
    width: fit-content;
}
.klbf-modal-container .content-container .cta-wrapper .claim-btn:hover {
    background-color: #0063c0; 
}

@media screen and (max-width: 1020px) {

}
@media screen and (max-width: 1024px) {
    .klbf-modal-container .content-container .desc {
        font-size: 22px;
    }
    .klbf-modal-container .content-container .desc .blue-highlight {
        font-size: 34.317px;
    }
    .klbf-modal-container .content-container .desc .bold-text {
        font-size: 34.317px;
    }
    
    .klbf-modal-container .content-container .cta-wrapper img{
        width: 200px;
    }
    .klbf-modal-container .content-container .cta-wrapper .claim-btn {
        font-size: 22px;
    }
}

@media screen and (max-width: 882px) {
    .klbf-modal-container .content-container .desc {
        font-size: 18px;
    }
    .klbf-modal-container .content-container .desc .blue-highlight {
        font-size: 26.317px;
    }
    .klbf-modal-container .content-container .desc .bold-text {
        font-size: 26.317px;
    }
    
    .klbf-modal-container .content-container .cta-wrapper img{
        width: 150px;
    }
    .klbf-modal-container .content-container .cta-wrapper .claim-btn {
        padding: 13px 32px;
        font-size: 16px;
    }
}

@media screen and (max-width: 722px) {
    .klbf-modal-container .image-container{
        display: none;
    }
    .klbf-modal-container .content-container{
        width: 100%;
    }
    .klbf-modal-container .content-container .cta-wrapper{
        justify-content: space-around;
    }
}
@media screen and (max-width: 420px) {
    .klbf-modal-container .content-container .title {
        font-size: 18px;
    }
    .klbf-modal-container .content-container .desc {
        font-size: 18px;
    }
    .klbf-modal-container .content-container .desc .blue-highlight {
        font-size: 22.317px;
    }
    .klbf-modal-container .content-container .desc .bold-text {
        font-size: 22.317px;
    }
    
    .klbf-modal-container .content-container .cta-wrapper img{
        width: 120px;
    }
    .klbf-modal-container .content-container .cta-wrapper .claim-btn {
        padding: 13px 28px;
        font-size: 14px;
    }
}
